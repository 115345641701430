import React, { useEffect, useState } from "react";
import VerificationInput from "react-verification-input";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderWithBack from "components/headers/HeaderWithBack";
import Layout from "components/Layout/Layout";
import DefaultButton from "components/buttons/DefaultButton";
import styles from "./ConfirmCode.module.scss";
import http from "../../helpers/http";
import { useAppDispatch } from "../../store";
import { setUserData } from "store/slices/user";
import { setToken } from "../../helpers/localStorageHelpers";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const ConfirmCode = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation().state;

  useEffect(() => {
    ReactPixel.track("Confirm Email Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/confirm-email",
      title: "Confirm Email Page",
    });
  }, []);

  const onLogin = async () => {
    try {
      const res: any = await http.post("/auth/login", {
        oneTimeCode: +verificationCode,
        email: location.email,
      });
      ReactGA.event("login");
      ReactPixel.trackCustom("login");
      dispatch(setUserData(res));
      setToken(res.token);
      navigate("/home");
    } catch (e) {
      setError("the confirmation code is incorrect");
    }
  };

  const resendCode = async () => {
    ReactGA.event("resendCode");
    ReactPixel.trackCustom("resendCode");
    await http.post("/auth/send-code", { email: location.email });
  };

  return (
    <Layout>
      <div className="container padding-b40">
        <div className={"position-relative height100"}>
          <HeaderWithBack />
          <div className={styles.titleContainer}>
            <h2 className="mb8">
              <span className="gradient-pink-text">Code </span>
              Confirmation
            </h2>
            <p className="text">
              Enter the code we've sent to your email to complete the sign-in
              process. Your cosmic journey awaits!
            </p>
          </div>
          <VerificationInput
            length={5}
            validChars="0-9"
            placeholder="0"
            onChange={(el) => {
              if (error) {
                setError("");
              }
              setVerificationCode(el);
            }}
            classNames={{
              container: styles.verificationContainer,
              character: classNames(styles.character, {
                [styles.characterError]: !!error,
              }),
              characterSelected: styles.characterSelected,
              characterFilled: styles.characterSelected,
            }}
            inputProps={{
              type: "text",
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
          <p className={styles.error}>{error}</p>
          <div className={classNames("text", "bottom-container-absolute")}>
            <DefaultButton
              text="Sign In"
              type="button"
              onClick={onLogin}
              isDisabled={verificationCode.length < 5 || !!error}
            />
            <p className="font-s18 mt16">
              Don’t receive a code?{" "}
              <span className={"linkBtn"} onClick={resendCode}>
                Request a new one
              </span>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ConfirmCode;
