import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import DefaultButton from "../../buttons/DefaultButton";
import "./styles.scss";
import MastercardIcon from "assets/svg/payment-icons/mastercard.svg";
import VisaIcon from "assets/svg/payment-icons/visa.svg";
import http from "helpers/http";
import { useAppDispatch, useAppSelector } from "store";
import { useNavigate } from "react-router-dom";
import { startLoading, stopLoading } from "store/slices/loader";
import { Subscription } from "views/PaymentPage/PaymentPage";
import * as stripeJs from "@stripe/stripe-js";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import {
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
} from "@stripe/stripe-js";
import DefaultInput from "../../fields/DefaultInput";
import StripeInput from "components/forms/StripeForm/StripeInput/StripeInput";
import CloseBtnIcon from "assets/svg/close-btn";
import { updateUserAccountExpiration } from "store/slices/user";
import { getCampainId } from "helpers/localStorageHelpers";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    boxShadow: "0 24px 45.20000076293945px 0 rgba(0, 0, 0, 0.25)",
    borderRadius: 24,
    padding: 24,
  },
};

const CARD_OPTIONS: StripeCardNumberElementOptions = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000",
      fontWeight: 500,
      fontSize: "14px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#4C556F" },
      "::placeholder": { color: "#4C556F" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#f80505",
    },
  },
};

const StripeModal = ({
  isOpen,
  setIsOpenStripe,
  toggleButtonModal,
  subscriptionData,
  paymentType,
}: {
  isOpen: boolean;
  setIsOpenStripe: (val: boolean) => void;
  toggleButtonModal: (val: boolean) => void;
  subscriptionData: Subscription;
  paymentType: string;
  }) => {
  const { email, genderType } = useAppSelector((state) => state.user.userData);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [cardName, setCardName] = useState("");

  const nameInputRef = useRef<HTMLInputElement>(null);

  console.log("subscriptionData", subscriptionData);
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const newPrice =
    subscriptionData.price === "$1"
      ? 100
      : +subscriptionData.price.replace(/[^0-9]/g, "");

  const localCampainId = getCampainId();
  const pixelData = localCampainId
    ? {
        value: newPrice / 100,
        currency: "EUR",
        utm_campaign: localCampainId,
      }
    : {
        value: newPrice / 100,
        currency: "EUR",
      };

  const handleSubmit = async () => {
    try {
      dispatch(startLoading());
      ReactGA.event("clickedPayButton");
      ReactPixel.trackCustom("clickedPayButton");
      ReactGA.event("clickPayment");
      if (elements) {
        const cardElement = elements.getElement(CardNumberElement);
        if (stripe && elements) {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement as StripeCardNumberElement,
            billing_details: {
              email,
            },
          });

          if (!error) {
            try {
              const { id } = paymentMethod;
              const requestUrl =
                paymentType === paymentType
                  ? "/payment/create-payment-intent"
                  : "/payment/create-payment-subscription";

              const res = await http.post(requestUrl, {
                packageType: subscriptionData.id,
                paymentMethod: id,
                email,
              });
              if (res.actionRequired) {
                dispatch(stopLoading());
                const { paymentIntent, error } =
                  await stripe.confirmCardPayment(res.clientSecret);
                if (error) return console.log(error);
                if (paymentIntent.status === "succeeded") {
                  dispatch(startLoading());
                  const res2 = await http.post(`/payment/payment-confirm`, {
                    id: res.id,
                    email,
                    packageType: subscriptionData.id,
                    stripeCustId: res.stripeCustId,
                  });
                  dispatch(updateUserAccountExpiration(res2.newExpirationDate));
                  dispatch(stopLoading());
                }
              } else {
                dispatch(updateUserAccountExpiration(res.newExpirationDate));
              }
              ReactGA.send({
                hitType: "pageview",
                page: "/payment",
                title: "Purchase",
                value: newPrice / 100,
                currency: "EUR",
              });
              ReactPixel.track("Purchase", pixelData);
              dispatch(stopLoading());
              setIsOpenStripe(false);
              setTimeout(() => {
                if (!genderType) {
                  navigate("/load");
                } else {
                  navigate("/home");
                }
              }, 200);
            } catch (error) {
              ReactGA.event("paymentError");
              ReactPixel.trackCustom("paymentError");
              dispatch(stopLoading());
              console.log("Error", error);
            }
          } else {
            ReactGA.event("paymentError");
            ReactPixel.trackCustom("paymentError");
            dispatch(stopLoading());
            console.log(error.message);
          }
        }
      }
      dispatch(stopLoading());
    } catch (e) {
      console.log(e);
      dispatch(stopLoading());
    }
  };

  const cancelPaymentMethod = () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/payment",
      title: "Cancel Pay",
    });
    ReactPixel.trackCustom("cancelPay");
  };

  const createPaymentRequest = async (event: any) => {
    try {
      if (stripe) {
        console.log("evee", event);
        const requestUrl = "/payment/create-payment-intent";
        const res = await http.post(requestUrl, {
          packageType: subscriptionData.id,
          email,
          paymentMethod: event.paymentMethod.id,
        });

        console.log("resssss", res);

        if (res.message === "succeeded") {
          dispatch(startLoading());
          const res2 = await http.post(`/payment/payment-confirm`, {
            id: res.id,
            email,
            packageType: subscriptionData.id,
            stripeCustId: res.stripeCustId,
          });
          dispatch(stopLoading());
          dispatch(updateUserAccountExpiration(res2.newExpirationDate));
        } else {
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              res.clientSecret,
              { payment_method: event.paymentMethod.id },
              { handleActions: false },
            );

          if (confirmError) {
            event.complete("fail");
          }

          if (paymentIntent?.status === "requires_action") {
            await stripe.confirmCardPayment(res.clientSecret);
            // The payment has succeeded -- show a success message to your customer.
          }
          dispatch(startLoading());
          const res2 = await http.post(`/payment/payment-confirm`, {
            id: res.id,
            email,
            packageType: subscriptionData.id,
            stripeCustId: res.stripeCustId,
          });
          dispatch(stopLoading());
          dispatch(updateUserAccountExpiration(res2.newExpirationDate));
        }

        dispatch(stopLoading());
        event.complete("success");
        ReactGA.send({
          hitType: "pageview",
          page: "/payment",
          title: "Purchase",
          value: newPrice / 100,
          currency: "EUR",
        });
        ReactPixel.track("Purchase", pixelData);
        setTimeout(() => {
          if (!genderType) {
            navigate("/load");
          } else {
            navigate("/home");
          }
        }, 200);
      }
    } catch (error) {
      console.error({ error });
      event.complete("fail");
    }
  };

  const onPaymentSubmit = () => {
    console.log("onPaymentSubmit");
    paymentRequest.on("paymentmethod", createPaymentRequest);
    paymentRequest.on("cancel", cancelPaymentMethod);
  };

  useEffect(() => {
    if (stripe) {
      const PaymentRequestRes = stripe.paymentRequest({
        country: "US",
        currency: "eur",
        total: {
          label: "Pay Fateful",
          amount: newPrice,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      PaymentRequestRes.canMakePayment().then((result: any) => {
        if (result) {
          setPaymentRequest(PaymentRequestRes);
        }
      });
    }
  }, [stripe, elements]);

  const handleCardNumberChange = (
    event: stripeJs.StripeCardNumberElementChangeEvent,
  ) => {
    if (event.complete) {
      ReactGA.event("paymentCardNumberEntered");
      ReactPixel.trackCustom("paymentCardNumberEntered");
      if (elements) {
        const el = elements.getElement(CardExpiryElement);
        if (el) {
          el.focus();
        }
      }
    }
  };

  const handleExpiryChange = (
    event: stripeJs.StripeCardExpiryElementChangeEvent,
  ) => {
    if (event.complete) {
      ReactGA.event("paymentCardExpiryEntered");
      ReactPixel.trackCustom("paymentCardExpiryEntered");
      if (elements) {
        const el = elements.getElement(CardCvcElement);
        if (el) {
          el.focus();
        }
      }
    }
  };

  const handleCvcChange = (event: stripeJs.StripeCardCvcElementChangeEvent) => {
    if (event.complete) {
      ReactGA.event("paymentCardCvcEntered");
      ReactPixel.trackCustom("paymentCardCvcEntered");

      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }
    }
  };

  const renderBtn = () => {
    console.log("paymentRequest", paymentRequest);
    if (paymentRequest) {
      return (
        <div className="full-width">
          <PaymentRequestButtonElement
            options={{ paymentRequest }}
            onClick={onPaymentSubmit}
          />
        </div>
      );
    }
  };

  const handleCardName = (event: ChangeEvent<HTMLInputElement>) => {
    setCardName(event.target.value);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpenStripe(false);
        toggleButtonModal(true);
      }}
      style={customStyles}
    >
      <div className="full-width flex flex-column i-align-c position-relative">
        <div
          className="flex i-align-c pointer position-absolute"
          style={{ top: -2, right: 0 }}
          onClick={() => {
            setIsOpenStripe(false);
            toggleButtonModal(true);
          }}
        >
          <CloseBtnIcon fill={"#000"} />
        </div>
        <p className="t-align-l full-width font-s13 text-color-dark-silver">
          You selected {subscriptionData.period}
        </p>
        <div className="line mt16" />
        <div className={"width350 flex flex-column i-align-c"}>
          {paymentRequest && (
            <>
              <div className="full-width mt8">{renderBtn()}</div>
              <div className="flex mt8 full-width i-align-c">
                <div className="line full-width" />
                <p className="font-s14">or</p>
                <div className="line full-width" />
              </div>
            </>
          )}
          <p className="mt24 mb8 full-width t-align-l font-s14 font-w600">
            Pay with card
          </p>
          <div className="flex gap8 mb16 justify-content-start full-width">
            <img src={VisaIcon} alt="" />
            <img src={MastercardIcon} alt="" />
          </div>
          <p className="font-s13 mb6 t-align-l full-width font-w600">
            Credit or debit card number
          </p>
          <StripeInput>
            <CardNumberElement
              options={CARD_OPTIONS}
              onChange={handleCardNumberChange}
            />
          </StripeInput>
          <div className="flex gap12 full-width">
            <div className="full-width">
              <p className="full-width t-align-l font-s14 font-w600  mt16 mb6">
                Expiry date
              </p>
              <StripeInput>
                <CardExpiryElement
                  options={CARD_OPTIONS}
                  onChange={handleExpiryChange}
                />
              </StripeInput>
            </div>
            <div className="full-width">
              <p className="full-width t-align-l font-s14 font-w600  mt16 mb6">
                Cvv/cvc
              </p>
              <StripeInput>
                <CardCvcElement
                  options={CARD_OPTIONS}
                  onChange={handleCvcChange}
                />
              </StripeInput>
            </div>
          </div>
          <div className="full-width">
            <p className="full-width t-align-l font-s14 font-w600 mt16 mb6">
              Name on card
            </p>
            <DefaultInput
              ref={nameInputRef}
              name="cardNumber"
              handleChange={handleCardName}
              inputVal={cardName}
              placeholder="Michael Smith"
              customStyles="custom-small-input text-black"
            />
          </div>
          <div className="full-width mt16">
            <DefaultButton text={`Pay`} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StripeModal;
