import React, { useEffect, useState } from "react";
import Layout from "components/Layout/Layout";
import SubscriptionCard from "./components/SubscriptionCard";
import DefaultButton from "components/buttons/DefaultButton";
import ShieldIcon from "assets/svg/shield";
import CertificateCard from "./components/CertificateCard/CertificateCard";
import Logo from "assets/svg/logo";
import XAppIcon from "assets/svg/x-app-icon";
import FaceBookIcon from "assets/svg/facebook-icon";
import InstagramIcon from "assets/svg/instagram-icon";
import { useNavigate } from "react-router-dom";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import {
  addClassToHiddenModalDiv,
  addClassToSpecificHiddenModalDiv,
} from "utils/defaults";
import { useInView } from "react-intersection-observer";
import HeaderWithHome from "components/headers/HeaderWithHome";
import PaymentDetailsModal from "components/modals/PaymentDetailsModal/PaymentDetailsModal";
import Loader from "../../components/Loader/Loader";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

export type Subscription = {
  type: string;
  id: string;
  price: string;
  period: string;
  perDay: string;
  oldPrice: string;
  oldDayPrice: string;
  label?: string;
};

const subscriptionsList: Subscription[] = [
  {
    type: "In a relationship",
    id: "6128eabd-e506-4fb1-b1bc-bc09b1287903",
    price: "$0.30",
    period: "6 month plan",
    perDay: "$55.55",
    oldPrice: "$18.99",
    label: "save 50%",
    oldDayPrice: "$0.55",
  },
  {
    type: "Broke up",
    id: "5f40db38-6307-45f9-b45b-0fba1660bcde",
    price: "$3.55",
    period: "3 month plan",
    perDay: "$45.99",
    oldPrice: "$79.99",
    label: "most popular",
    oldDayPrice: "$0.89",
  },
  {
    type: "Engaged",
    id: "b952905f-28c9-4dae-b00b-cc51529782c6",
    price: "$9.99",
    period: "1 month plan",
    perDay: "$18.99",
    oldPrice: "$29.99",
    oldDayPrice: "$0.99",
  },
];
const AppPaymentPage = () => {
  const navigate = useNavigate();

  const [subscriptionType, setSubscriptionType] = useState("Broke up");
  const [isButtonModalOpen, toggleButtonModal] = useState(false);
  const [isPaymentDetailsModalOpen, togglePaymentDetailsModal] =
    useState(false);

  const [trialBtnRef, btnInView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    ReactPixel.track("App Payment Page");
    ReactGA.send({
      hitType: "pageview",
      page: "/app-payment",
      title: "App Payment Page",
    });
  }, []);

  const scrollableContainer = document.getElementById("payment-container");

  useEffect(() => {
    if (btnInView) {
      toggleButtonModal(false);
    } else {
      toggleButtonModal(true);
      addClassToHiddenModalDiv();
    }
  }, [btnInView]);

  useEffect(() => {
    const container = document.getElementById("payment-container");
    if (isPaymentDetailsModalOpen) {
      addClassToSpecificHiddenModalDiv(1);
      if (container) container.style.overflow = "hidden";
    } else {
      if (container) container.style.overflow = "auto";
    }
  }, [isPaymentDetailsModalOpen]);

  const buttonModalHeader = () => {
    return (
      <DefaultButton
        type="button"
        text="Change My Plan"
        customStyles="button-shadow"
        onClick={() => {
          toggleButtonModal(false);
          scrollableContainer &&
            scrollableContainer.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
    );
  };

  return (
    <Layout>
      <div className="container" id="payment-container">
        <HeaderWithHome />
        <h2 className="yellow-text mb8">Choose your plan</h2>
        <p className="text">Please select a plan</p>
        <div className="mt24 mb32">
          {subscriptionsList.map((subscription) => (
            <SubscriptionCard
              itemData={subscription}
              key={subscription.id}
              label={subscription.label}
              isSelected={subscriptionType === subscription.type}
              setType={
                isPaymentDetailsModalOpen ? undefined : setSubscriptionType
              }
            />
          ))}
        </div>
        <div ref={trialBtnRef}>
          <DefaultButton
            text="Change my Plan"
            type="button"
            onClick={() => togglePaymentDetailsModal(true)}
            customStyles="button-shadow"
          />
        </div>
        <p className="text-color-silver fMontserrat font-s10 text-uppercase mt32 mb16 t-align-l">
          Your 7-day trial at $5.49 is set to conclude on January 11, 2024.
          After the trial period, a charge of $19.99 will be applied every 2
          weeks unless the subscription is canceled within the app or by
          contacting customer support at least 24 hours before the next billing
          date. Cancellation is possible at any time within the app or by
          reaching out to customer support. Prior to requesting a refund, please
          familiarize yourself with our
          <span className="text-color-blue"> Money-Back Guarantee Policy</span>.
          Need assistance? Reach out for help
          <span className="text-color-blue"> support@fatful.app</span>
        </p>
        <button
          type="button"
          className="linkBtn font-s16 font-w400"
          onClick={() => {
            console.log();
          }}
        >
          Subscription Terms
        </button>
        <p className="mt16 flex i-align-c text-white fMontserrat font-s13 mb40 gap4">
          <ShieldIcon /> Guaranteed safe checkout
        </p>
        <CertificateCard />
        <div
          className="flex justify-content-start i-align-s text-color-silver flex-column fMontserrat font-s16 font-w400 padding-t40"
          style={{ paddingBottom: 140 }}
        >
          <Logo />
          <div className="mt32 mb40 flex i-align-s">
            <div
              className="t-align-l flex flex-column gap16"
              style={{ marginRight: 48 }}
            >
              <p>About us</p>
              <p>Blog</p>
              <p>Contact us</p>
              <p>FAQ</p>
            </div>
            <div className="t-align-l gap16 flex flex-column">
              <p onClick={() => navigate("/privacy-policy")}>Privacy Policy</p>
              <p>Terms of Use</p>
              <p>Billing Terms</p>
            </div>
          </div>
          <div className="flex i-align-c gap16" style={{ margin: "0 auto" }}>
            <XAppIcon /> <FaceBookIcon /> <InstagramIcon />
          </div>
        </div>
        <CustomHeightBottomSheet
          modalHeight={120}
          renderBackdrop={false}
          header={buttonModalHeader}
          isModalOpen={isButtonModalOpen}
          setOpen={toggleButtonModal}
        />
        <PaymentDetailsModal
          planData={
            subscriptionsList.filter(
              (item) => item.type === subscriptionType,
            )[0]
          }
          isOpen={isPaymentDetailsModalOpen}
          toggleModal={togglePaymentDetailsModal}
        />
      </div>
      <Loader />
    </Layout>
  );
};

export default AppPaymentPage;
