import React, { useState, useEffect, useRef, FC } from "react";

interface ITimerProps {
  time: number;
}

const Timer: FC<ITimerProps> = ({ time }) => {
  const [timer, setTimer] = useState(time);
  const intervalRef = useRef<any>(null);

  const startTimer = () => {
    const startTime = Date.now();
    intervalRef.current = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      setTimer(time - elapsedTime);
    }, 1000);
  };

  useEffect(() => {
    startTimer();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [time]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  const minutes: number = Math.floor(timer / 60);
  const seconds: number = timer % 60;

  return (
    <>
      {timer > 0 ? (
        <p
          className="brownish-gray-text font-s26 font-w600 fMontserrat"
          style={{ width: 69 }}
        >
          {minutes.toString()}:{seconds.toString().padStart(2, "0")}
        </p>
      ) : (
        <p
          className="text-white font-s26 font-w600 fMontserrat"
          style={{ width: 69 }}
        >
          00:00
        </p>
      )}
    </>
  );
};

export default Timer;
