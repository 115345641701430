import React from "react";
const ConfusedIcon = () => (
  <svg width="20" height="34" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.1075 0.574357C15.5566 0.219397 14.8222 0.378301 14.4676 0.929317C14.1128 1.48045 14.2715 2.21464 14.8225 2.5696C15.8142 3.20629 16.4477 3.75601 16.7869 4.16077C17.1338 4.57246 17.1562 4.7869 17.1532 4.81867C17.1506 4.84621 17.1564 4.84667 17.1166 4.913C17.0546 5.02351 16.775 5.26736 16.3439 5.46965C15.7036 5.78396 14.7924 6.0263 14.067 6.16655C13.703 6.23815 13.3837 6.28761 13.1586 6.31843C13.075 6.32979 13.0046 6.33894 12.9496 6.34561C12.9216 6.34351 12.8934 6.34128 12.8648 6.34128L7.13479 6.34116C7.10561 6.34116 7.0769 6.34339 7.04842 6.3455C6.66277 6.29956 5.5033 6.13351 4.48003 5.7959C3.92221 5.61613 3.41128 5.37602 3.12979 5.15699C2.98741 5.04964 2.9069 4.95425 2.87573 4.90129C2.84432 4.84539 2.84842 4.8448 2.84608 4.81843C2.84327 4.78668 2.86542 4.57222 3.21241 4.16053C3.55189 3.75577 4.18543 3.20617 5.17717 2.56936C5.72806 2.2144 5.88685 1.48021 5.53225 0.929444C5.17753 0.378314 4.44298 0.219404 3.89197 0.574364C2.77459 1.29577 1.97032 1.95646 1.39963 2.62888C0.836319 3.29416 0.47536 4.024 0.47269 4.8184C0.469877 5.33929 0.644487 5.83513 0.900789 6.21808C1.35643 6.8932 1.98289 7.28953 2.61958 7.60489C3.58039 8.06743 4.63462 8.32736 5.47402 8.49494C5.65777 8.53115 5.83003 8.56185 5.98846 8.58834V32.1662C5.98846 33.1792 6.80971 33.9999 7.82209 33.9999C8.83483 33.9999 9.65608 33.1792 9.65608 32.1662V18.7772H10.3435V32.1662C10.3435 33.1792 11.1648 33.9999 12.1771 33.9999C13.1899 33.9999 14.0111 33.1792 14.0111 32.1662V8.58834C14.5476 8.49857 15.2511 8.35642 15.9772 8.13926C16.7243 7.91145 17.4972 7.61591 18.1793 7.13882C18.5192 6.89777 18.8417 6.60515 19.0983 6.21809C19.3545 5.83511 19.5294 5.3393 19.5264 4.81841C19.5237 4.02401 19.1628 3.29414 18.5996 2.62889C18.0288 1.95635 17.2248 1.29566 16.1076 0.574253L16.1075 0.574357Z" fill="url(#paint0_linear_186_12721)"/>
  <defs>
  <linearGradient id="paint0_linear_186_12721" x1="-0.765842" y1="44.0843" x2="27.7142" y2="38.4694" gradientUnits="userSpaceOnUse">
  <stop stop-color="#5784F8"/>
  <stop offset="1" stop-color="#F5B6FF"/>
  </linearGradient>
  </defs>
  </svg>
  
);

export default ConfusedIcon;
