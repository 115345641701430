import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "components/Layout/Layout";
import DefaultInput from "components/fields/DefaultInput";
import DefaultButton from "components/buttons/DefaultButton";
import { isValidEmail } from "utils/validations";
import HeaderWithBack from "components/headers/HeaderWithBack";
import { clearPersistor, useAppDispatch } from "store";
import { clearRegistrationState } from "store/slices/registration";
import http from "helpers/http";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { setCampainId } from "../../helpers/localStorageHelpers";

// import LogRocket from "logrocket";
// LogRocket.init("nwxyyv/Fateful");

const Login: FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isBtnDisabled, disableBtn] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const campainId = searchParams.get("campainId");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    ReactPixel.track("Login Page");
    ReactGA.send({ hitType: "pageview", page: "/login", title: "Login Page" });
    clearPersistor();
    if (campainId) {
      http.post("/analytic/save-campain-id", { campainId });
      setCampainId(campainId);
      navigate("/registration", { replace: true });
    }
  }, []);

  const onEnterEmail = (event: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError("");
    }
    setEmail(event.target.value);
  };
  const onLogin = async () => {
    ReactGA.event("sendConfirmCode");
    ReactPixel.trackCustom("sendConfirmCode");
    if (isValidEmail(email)) {
      try {
        disableBtn(true);
        await http.post("/auth/send-code", { email });
        navigate("/confirm-email", { state: { email } });
        disableBtn(false);
      } catch (e) {
        disableBtn(false);
        setError("we can’t find your account");
      }
    } else {
      setError("Please provide an email");
    }
  };

  const navigateToSignUp = () => {
    ReactGA.event("signIn");
    ReactPixel.trackCustom("signIn");
    dispatch(clearRegistrationState());
    navigate("/registration");
  };

  return (
    <Layout>
      <div className="container padding-b40">
        <div className={"position-relative height100"}>
          <HeaderWithBack />
          <div style={{ marginBottom: 154 }}>
            <h2 className="mb8">
              <span className="gradient-pink-text">Sign in </span>
              <span className="brownish-gray-text">to Fateful</span>
            </h2>
            <p className="text">Access your cosmic insights instantly.</p>
          </div>
          <DefaultInput
            handleChange={onEnterEmail}
            name="emailAddress"
            placeholder="youremail@gmail.com"
            error={error}
            inputVal={email}
          />
          <div className={"text bottom-container-absolute"}>
            <DefaultButton
              text="Sign In"
              type="button"
              onClick={onLogin}
              isDisabled={isBtnDisabled || (!email ? true : !!error)}
            />
            <p className="font-s18 mt16">
              Don’t have an account?{" "}
              <button
                type="button"
                className="linkBtn"
                onClick={navigateToSignUp}
              >
                Sign Up
              </button>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
