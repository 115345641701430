import React, {useEffect, useState} from "react";
import LogoIcon from "assets/svg/logoIcon.svg";
import Layout from 'components/Layout/Layout';
import cloudsImage from "assets/svg/clouds-img.svg"
import styles from "./PaymentNewPage.module.scss"
import classNames from "classnames";
import Add from "assets/svg/add.svg"
import DefaultButton from "components/buttons/DefaultButton";
import Checkmark from "assets/svg/checkmark.svg";
import safetyIcon from "assets/svg/safe-payment.svg";
import Visa from "assets/svg/visa.svg"
import MasterCard from "assets/svg/master-card.svg"
import AmericanExpress from "assets/svg/american-express.svg"
import PayPal from "assets/svg/paypal.svg"
import ServicesCarouselPic from "assets/svg/services-carousel-pic.svg"
import Quotes from "assets/svg/quotes.svg"
import GuranteeFaded from "assets/svg/guarantee-faded.svg"
import Logo from "assets/svg/logo";
import XAppIcon from "assets/svg/x-app-icon";
import FaceBookIcon from "assets/svg/facebook-icon";
import InstagramIcon from "assets/svg/instagram-icon";
import {useLocation, useNavigate} from "react-router-dom";
import {getPaymentLocation} from "helpers/sessionStorageHelpers";
import {useAppDispatch, useAppSelector} from "store";
// import LogRocket from "logrocket";
import {loadStripe} from "@stripe/stripe-js";
import {getToken} from "helpers/localStorageHelpers";
import {goToInfoAboutPalm} from "store/slices/registration";
import {AutoPlay} from "@egjs/flicking-plugins";
import {useInView} from "react-intersection-observer";
import {
    addClassToModalDiv,
    addClassToSpecificHiddenModalDiv,
} from "utils/defaults";
import {stopLoading} from "store/slices/loader";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import Timer from "components/Timer/Timer";
import {subscriptionsList} from "views/PaymentPage/components/SubscriptionList";
import StripeModal from "components/modals/StripeModal/StripeModal";
import PartyIcon from "assets/svg/party.svg";
import {Elements} from "@stripe/react-stripe-js";
import EmailModal from "views/PaymentPage/components/EmailModal/EmailModal";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import Loader from "components/Loader/Loader";

const {REACT_APP_STRIPE_SK} = process.env;
// LogRocket.init("nwxyyv/Fateful");

const stripePromise = loadStripe(`${REACT_APP_STRIPE_SK}`);

type Pricing = {
    price: string;
    duration: string;
    priceByDay: string;
    id: number;
    class: string;
    extraTag: string;
};

const PricingList: Pricing[] = [
    {price: "10", duration: "1 week trial", priceByDay: "USD 1.7/day", id: 0, class: "", extraTag: ""},
    {price: "13", duration: "10 days trial", priceByDay: "USD 1.3/day", id: 1, class: "mostPopular", extraTag: "Most Popular"},
    {price: "17", duration: "2 week trial", priceByDay: "USD 1.21/day", id: 2, class: "", extraTag: ""},
    {price: "25", duration: "1 Month trial", priceByDay: "USD 0.83/day", id: 3, class: "", extraTag: ""},
    {price: "50", duration: "3 Monthtrial", priceByDay: "USD 0.55/day", id: 4, class: "save50", extraTag: "Save 50%"},
];

const PaymentNewPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const paymentLocation = getPaymentLocation();
    const dispatch = useAppDispatch();

    const [subscriptionType, setSubscriptionType] = useState(
        "62fca830-750c-4784-8482-7a3799d55d4a",
    );
    const {userData} = useAppSelector((state) => state.user);
    const [readMoreHeight, setReadMoreHeight] = useState<number | string>(58);
    const {zodiacsArray} = useAppSelector((state) => state.compatibility);
    const token = getToken();

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener("popstate", function () {
            if (token) {
                navigate("/login");
            } else {
                dispatch(goToInfoAboutPalm());
                navigate("/registration");
            }
        });
        return window.removeEventListener("popstate", () => {
            console.log("remove");
        });
    }, [location]);

    const plugins = [
        new AutoPlay({duration: 2000, direction: "NEXT", stopOnHover: false}),
    ];

    const [isModalOpen, toggleModal] = useState(false);
    console.log('isModalOpen: ', isModalOpen);
    const [isButtonModalOpen, toggleButtonModal] = useState(false);
    const [isStripeOpen, setIsOpenStripe] = useState(false);

    const [subscriptionData, setSubscriptionData] = useState(
        subscriptionsList[1],
    );

    const [isModalOpenFirstTime, toggleModalFirstTime] = useState(true);

    const [trialBtnRef, btnInView] = useInView({
        threshold: 0,
    });

    const scrollableContainer = document.getElementById("payment-container");

    useEffect(() => {
        const container = document.getElementById("main-container");
        if (isStripeOpen) {
            if (container) container.style.overflow = "hidden";
        } else if (!isStripeOpen) {
            if (container) container.style.overflow = "scroll";
        }
    }, [isStripeOpen]);

    useEffect(() => {
        const container = document.getElementById("main-container");
        if (!paymentLocation) {
            navigate("/login");
        } else if (
            isModalOpenFirstTime &&
            typeof userData.expirationDate === "undefined"
        ) {
            if (container) container.style.overflow = "hidden";
            toggleModal(true);
            toggleModalFirstTime(false);
        }
        addClassToModalDiv();
    }, [isModalOpenFirstTime, isModalOpen]);

    useEffect(() => {
        if (
            paymentLocation === "registration" ||
            paymentLocation === "analysing" ||
            typeof location?.state?.from === "undefined"
        ) {
            addClassToSpecificHiddenModalDiv(0);
            // addClassToSpecificHiddenModalDiv(2);
        } else if (paymentLocation === "login") {
            addClassToSpecificHiddenModalDiv(0);
        } else {
            addClassToSpecificHiddenModalDiv(1);
            // addClassToSpecificHiddenModalDiv(2);
        }
    }, [btnInView]);

    useEffect(() => {
        dispatch(stopLoading());
        if (paymentLocation === "analysing") {
            toggleButtonModal(true);
        }
    }, [paymentLocation]);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/payment",
            title: "Payment Page",
        });
        ReactPixel.track("Payment Page");
    }, []);

    const buttonModalHeader = () => {
        return (
            <div className="flex i-align-c justify-content-space-between">
                <Timer time={600} />
                <DefaultButton
                    type="button"
                    text="Get Personal Prediction"
                    customStyles="font-s16 width74 btn-prediction"
                    onClick={() => {
                        scrollableContainer &&
                            scrollableContainer.scrollIntoView({
                                behavior: "smooth",
                            });
                    }}
                />
            </div>
        );
    };


    const userZodiac = zodiacsArray.filter(
        (item) => item.title === userData.zodiacName,
    )[0];

    const pressButton = () => {
        scrollableContainer &&
            scrollableContainer.scrollIntoView({
                behavior: "smooth",
            });
        setSubscriptionType("62fca830-750c-4784-8482-7a3799d55d4a");
        toggleButtonModal(false);
        setSubscriptionData(subscriptionsList[1]);
        setIsOpenStripe(true);
    };


    return (
        <Layout>
            <div className="app-container" id="main-container">
                <div className="t-align-l px6 padding-h16"
                    id="payment-container"
                >
                    <img src={LogoIcon} alt="" style={{width: 135}} />
                </div>
                <img src={cloudsImage} alt='' style={{width: "100%"}} />

                <Elements stripe={stripePromise}>
                <div className="padding-content-h">

                    <h1>
                        Please <span className='gradient-pink-text'> select </span> a plan
                    </h1>


                        <div className='text'> Your discount will expire in 10:00 </div>

                    <div className={classNames(
                        styles.pricingStackCardsContainer
                    )}>
                            {subscriptionsList.map((subscription) => {
                                return (
                                    <div key={subscription.id}
                                        className={classNames(styles.card, styles[subscriptionType === subscription.id ? "mostPopular" : ""])

                                        }

                                        onClick={() => {
                                            if (!isModalOpen) {
                                                setSubscriptionType(subscription.id);
                                                // setPriceId(subscription.priceId);
                                                toggleButtonModal(false);
                                                setSubscriptionData(subscription);
                                                setIsOpenStripe(true);
                                            }
                                        }}
                                    >
                                        {subscription.label && <div className={classNames(styles.gradientBGMsg)} style={{display: "none"}}>{subscription.label}</div>}
                                        {/* <div className={classNames(styles.gradientBGMsg)} style={{display: "none"}}>{subscription.label}</div> */}
                                        <div className={classNames(styles.duration)}>{subscription.period}</div>
                                        <div className={classNames(styles.price)}>{subscription.price}</div>
                                        <div className={classNames(styles.priceByDay)}>{subscription.perDay}</div>
                                    </div>
                                )
                            }



                            )}

                        <div className={classNames(styles.card)}>
                            <img src={Add} />
                            <div className={classNames(styles.price)} style={{fontSize: "12px", lineHeight: "12px"}}>Add your
                                custom plan</div>

                        </div>
                    </div>

                        <div className='mt40 full-width' ref={trialBtnRef}>
                        <DefaultButton
                            type="button"
                            text="Start Trial"
                                onClick={() => {
                                    console.log("open stripe");
                                    toggleButtonModal(false);
                                    setTimeout(() => setIsOpenStripe(true), 100);
                                }}
                                isDisabled={isModalOpen}

                                customStyles="font-s16 full-width btn-prediction"
                        />

                            {isStripeOpen && (
                                <StripeModal
                                    isOpen={true}
                                    paymentType="payment"
                                    setIsOpenStripe={setIsOpenStripe}
                                    toggleButtonModal={toggleButtonModal}
                                    subscriptionData={subscriptionData}
                                />
                            )}
                    </div>
                    <div className="fRoboto font-s10 font-w400 text-color-silver t-align-l flex gap6 justify-content-start i-align-s mt8">
                        <div
                            className=""
                            style={{
                                width: 12,
                                height: 12,
                                border: "1px solid #FFD700",
                                boxShadow: "0 0 1px 0 #FFD700",
                                borderRadius: 2,
                                padding: "0 1px",
                            }}
                        >
                            <img src={Checkmark} alt="" width={8} height={8} />
                        </div>
                        <p>
                            By continuing I accept
                            <span
                                className="gradient-pink-text pointer"
                            >
                                {" "}
                                Privacy Policy
                            </span>
                            ,<span className="gradient-pink-text"> Terms of Use</span>,
                            <span className="gradient-pink-text"> Billing Terms</span> and
                            <span className="gradient-pink-text"> Money-back Policy</span>.
                        </p>
                    </div>

                    <div className="flex justify-content-center i-align-s gap6 font-s14 text mt24">
                        <img src={safetyIcon} />
                        Guaranteed safe checkout
                    </div>

                    <div className="flex justify-content-center i-align-s gap6 font-s14 text mt24">
                        <img src={Visa} />
                        <img src={MasterCard} />
                        <img src={AmericanExpress} />
                        <img src={PayPal} />
                    </div>

                    <h1 className="mt40">Meet Our <span>Services</span></h1>

                    <img src={ServicesCarouselPic} className='mt12' />

                    <h1 className='mt40'>What People Are Saying</h1>

                    <div className={classNames(styles.tastimonialWrapper, "text")}>
                        <img src={Quotes} className={classNames(styles.quotes1)} width={18} height={18} />
                        <img src={Quotes} className={classNames(styles.quotes2)} width={18} height={18} />
                        Fateful's Palm Reading feature is incredibly accurate and detailed. The Love Compatibility section is fun and surprisingly insightful. I love using the Biorhythms tool to plan my day, and the Tarot Reading adds a mystical touch with its profound guidance.
                    </div>
                </div>
                </Elements>

                <div className={classNames(styles.joinedPersonsWrapper)}>
                    <div className={classNames(styles.joinedPersonsClipped1)}>
                        <div> <span className="gradient-pink-text font-w700"> 140k+ </span> already joined </div>
                        <img src={PartyIcon} alt="" />
                        <div> <span className="gradient-pink-text font-w700"> 140k+ </span> already joined </div>
                        <img src={PartyIcon} alt="" />
                        <div> <span className="gradient-pink-text font-w700"> 140k+ </span> already joined </div>
                        <img src={PartyIcon} alt="" />
                    </div>

                    <div className={classNames(styles.joinedPersonsClipped2)}>
                        <div> <span className="gradient-pink-text font-w700"> 140k+ </span> already joined </div>
                        <img src={PartyIcon} alt="" />
                        <div> <span className="gradient-pink-text font-w700"> 140k+ </span> already joined </div>
                        <img src={PartyIcon} alt="" />
                        <div> <span className="gradient-pink-text font-w700"> 140k+ </span> already joined </div>
                        <img src={PartyIcon} alt="" />
                    </div>
                </div>

                <div className="padding-content-h">
                    <div className={classNames(styles.moneyBackGuaranteeCard)}>
                        <img src={GuranteeFaded} alt="" className={classNames(styles.stickedFadedGuranteeBadge)} />
                        <h3> <span style={{color: "#5784F8"}}>100% </span> Money-back guarantee</h3>
                        <div className='text mt4'>
                            At Fateful, our goal is to offer the best. If you don't see progress within the first week, we'll refund you in <span style={{color: "#5784F8"}}> 21 days. </span>
                        </div>
                        <div className="font-s10 font-w600 brownish-gray-text mt8">Learn more about the specific constraints outlined in our <span className="gradient-pink-text"> Money-Back Guarantee Policy. </span> </div>
                    </div>

                    <div
                        className="flex justify-content-start i-align-s text-color-silver flex-column fMontserrat font-s16 font-w400 padding-t40"
                        style={{paddingBottom: 60}}
                    >
                        <img src={LogoIcon} alt="" style={{width: 135}} />

                        <div className="mt32 mb40 flex i-align-s">
                            <div
                                className="t-align-l flex flex-column gap16"
                                style={{marginRight: 48}}
                            >
                                <p>About us</p>
                                <p>Blog</p>
                                <p>Contact us</p>
                                <p>FAQ</p>
                            </div>
                            <div className="t-align-l gap16 flex flex-column">
                                <p>Privacy Policy</p>
                                <p>Terms of Use</p>
                                <p>Billing Terms</p>
                            </div>
                        </div>
                        <div className="flex i-align-c gap16" style={{margin: "0 auto"}}>
                            <XAppIcon /> <FaceBookIcon /> <InstagramIcon />
                        </div>
                    </div>

                    {paymentLocation === "registration" && (
                        <EmailModal
                            isModalOpen={isModalOpen}
                            toggleModal={toggleModal}
                            toggleButtonModal={toggleButtonModal}
                        />
                    )}

                    <CustomHeightBottomSheet
                        modalHeight={120}
                        renderBackdrop={false}
                        header={buttonModalHeader}
                        isModalOpen={isButtonModalOpen}
                        setOpen={toggleButtonModal}
                    />


                </div>
            </div>
            <Loader />
        </Layout>
    );
}

export default PaymentNewPage;