import React from "react";

const LookingForLoveIcon = () => (
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.8128 0.983999C5.00162 0.983999 0.265625 5.72 0.265625 11.5568C0.265625 17.368 5.00162 22.104 10.8128 22.104C12.7072 22.104 14.576 21.592 16.24 20.5936L16.624 20.3632L21.4624 25.2016C21.7696 25.5344 22.2048 25.688 22.6656 25.688C23.1264 25.688 23.536 25.5088 23.8687 25.2016C24.1759 24.8944 24.3552 24.4591 24.3552 23.9984C24.3552 23.5376 24.176 23.128 23.8687 22.7952L19.1327 18.0848L19.5423 17.4704C20.7199 15.7296 21.3599 13.6816 21.3599 11.5568C21.3855 5.72002 16.6495 0.984021 10.8127 0.984021L10.8128 0.983999ZM10.8128 19.2368C6.56322 19.2368 3.10722 15.7808 3.10722 11.5312C3.10722 7.2816 6.56322 3.8256 10.8128 3.8256C15.0624 3.8256 18.5184 7.2816 18.5184 11.5312C18.5184 15.8064 15.0624 19.2368 10.8128 19.2368Z" fill="url(#paint0_linear_186_12716)"/>
  <defs>
  <linearGradient id="paint0_linear_186_12716" x1="-1.30019" y1="33.0992" x2="32.2473" y2="21.7211" gradientUnits="userSpaceOnUse">
  <stop stop-color="#5784F8"/>
  <stop offset="1" stop-color="#F5B6FF"/>
  </linearGradient>
  </defs>
  </svg>
  
);

export default LookingForLoveIcon;
