import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken } from "./localStorageHelpers";
const { REACT_APP_SERVICE_URL } = process.env;
export interface IRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
  _disableErrorHandler?: boolean;
}

export const apiInstance = axios.create({
  baseURL: REACT_APP_SERVICE_URL,
});

apiInstance.interceptors.request.use(
  (config) => {
    const auth = getToken();
    config.headers = config.headers;
    config.headers.Authorization = `Bearer ${auth}`;
    return config;
  },
  (error) => Promise.reject(error),
);

interface IErrorResponse {
  message: string;
  status: string;
}

const makeHttpRequest = async <TResponse, TError = IErrorResponse>(
  apiCall: () => Promise<AxiosResponse<TResponse>>,
): Promise<TResponse> => {
  try {
    const response = await apiCall();
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data as TError;
    } else {
      throw new Error("Network error or other unexpected issue");
    }
  }
};

export const http = {
  get: (url: string, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.get(url, options)),
  post: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.post(url, data, options)),
  put: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.put(url, data, options)),
  patch: (url: string, data?: any, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.patch(url, data, options)),
  delete: (url: string, options?: IRequestConfig) =>
    makeHttpRequest(() => apiInstance.delete(url, options)),
};

export default http;